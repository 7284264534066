import React from 'react';
import classnames from 'classnames';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import _ from 'lodash';

import { Select } from 'utilities/components/inputs/select/view';
import { Errors } from 'utilities/components/errors/view';
import { Img } from 'utilities/components/img/component';

import attachment from 'assets/images/attachment.svg';
import doorOutside from 'assets/images/your-project/door-outside.png';
import trapezoids from 'assets/images/your-project/trapezoids.svg';

import './viev.scss';

SwiperCore.use([Navigation, Pagination]);

export const ContactFormView = ({
    fields,
    files,
    doorTypes,
    maxFiles,
    setFiles,
    setMaxFiles,
    removeFile,
    validateSingleField,
    onSubmit,
    onChangeSelect,
    onChange,
    isSendSuccess,
    isSendError
}) => (
    <section className="contact">
        <div className="contact__info">
            <h1 className="contact__title">Realizujemy najbardziej niestandardowe projekty drzwi zewnętrznych</h1>
            <p className="contact__description">
                Jesteśmy przekonani o najwyższej jakości naszych produktów. Jednocześnie zależy nam na tym, by nasi klienci mieli nieograniczoną swobodę wyboru.
                Dlatego z chęcią realizujemy nawet najbardziej niestandardowe projekty, zgodnie z wytycznymi klienta.
            </p>
            <div className="contact__images">
                <Img className="contact__image-trapezoids" src={ trapezoids } alt="" />
                <Img className="contact__image-door" src={ doorOutside } alt="drzwi zewnętrzne" />

            </div>
        </div>
        <div className="contact__form">
            {/* TODO - action */}
            <form action="" className="form" onSubmit={ onSubmit }>
                <h3 className="form__title">Wyślij swój projekt</h3>
                <div className="form__content">
                    <Select
                        options={ doorTypes }
                        value={ fields.doorType.value }
                        placeholder={ doorTypes[0] }
                        onChange={ onChangeSelect }
                        errors={ fields.doorType.errors }
                    />
                    <div className="form__name-wrapper">
                        <div className={ classnames('form__input', { 'form__input--not-empty': fields.firstName.value }, { 'form__input--required': fields.firstName.required }, { 'form__input--error': Boolean(fields.firstName.errors.size) }) }>
                            <input type="text" id={ fields.firstName.name } maxLength={ fields.firstName.maxLength } name={ fields.firstName.name } value={ fields.firstName.value } onChange={ (e) => onChange(e) } onBlur={ () => validateSingleField(fields.firstName.name) } />
                            <label htmlFor={ fields.firstName.name }>Imię</label>
                            <Errors errors={ fields.firstName.errors } />
                        </div>
                        <div className={ classnames('form__input', { 'form__input--not-empty': fields.lastName.value }, { 'form__input--required': fields.lastName.required }, { 'form__input--error': Boolean(fields.lastName.errors.size) }) }>
                            <input type="text" id={ fields.lastName.name } maxLength={ fields.lastName.maxLength } name={ fields.lastName.name } value={ fields.lastName.value } onChange={ (e) => onChange(e) } onBlur={ () => validateSingleField(fields.lastName.name) } />
                            <label htmlFor={ fields.lastName.name }>Nazwisko</label>
                            <Errors errors={ fields.lastName.errors } />
                        </div>
                    </div>
                    <div className={ classnames('form__input', { 'form__input--not-empty': fields.email.value }, { 'form__input--required': fields.email.required }, { 'form__input--error': Boolean(fields.email.errors.size) }) }>
                        <input type="text" id={ fields.email.name } maxLength={ fields.email.maxLength } name={ fields.email.name } value={ fields.email.value } onChange={ (e) => onChange(e) } onBlur={ () => validateSingleField(fields.email.name) } />
                        <label htmlFor={ fields.email.name }>E-mail</label>
                        <Errors errors={ fields.email.errors } />
                    </div>
                    <div className={ classnames('form__input', { 'form__input--not-empty': fields.phone.value }, { 'form__input--required': fields.phone.required }, { 'form__input--error': Boolean(fields.phone.errors.size) }) }>
                        <input type="text" id={ fields.phone.name } maxLength={ fields.phone.maxLength } name={ fields.phone.name } value={ fields.phone.value } onChange={ (e) => onChange(e) } onBlur={ () => validateSingleField(fields.phone.name) } />
                        <label htmlFor={ fields.phone.name }>Telefon</label>
                        <Errors errors={ fields.phone.errors } />
                    </div>
                    <div className={ classnames('form__input', 'form__input--last', { 'form__input--not-empty': fields.message.value }, { 'form__input--required': fields.message.required }, { 'form__input--error': Boolean(fields.message.errors.size) }) }>
                        <textarea id={ fields.message.name } maxLength={ fields.message.maxLength } name={ fields.message.name } value={ fields.message.value } onChange={ (e) => onChange(e) } onBlur={ () => validateSingleField(fields.message.name) } />
                        <label htmlFor={ fields.message.name }>Wiadomość</label>
                        <Errors errors={ fields.message.errors } />
                        <p className="form__text">
                            * Dane wymagane. Bez nich ani rusz ;)
                        </p>
                    </div>
                </div>
                <div className="form__input-file-wrapper">
                    <Dropzone
                        onDrop={ (acceptedFiles) => {
                            setFiles((prevState) => {
                                const prevFiles = _.cloneDeep(prevState);
                                prevFiles.push(...acceptedFiles);
                                setMaxFiles(10 - prevFiles.length);

                                return prevFiles;
                            });
                        } }
                        maxFiles={ maxFiles }
                        disabled={ maxFiles <= 0 }
                        // in bytes per one file
                        maxSize={ 1024 * 1024 * 10 }
                    >
                        {({ getRootProps, getInputProps }) => (
                            <>
                                {/* eslint-disable-next-line */}
                                <div className={ classnames('form__input-file', { 'form__input-file--hide': maxFiles <= 0 }) } { ...getRootProps() } >
                                    <input id="drop-files" { ...getInputProps() } />
                                    <label htmlFor="drop-files">
                                        <>
                                            <p>Upuść plik lub kliknij, aby dodać</p>
                                            <p>Możesz dodać maksymalnie 10 plików.</p>
                                            <p>Waga jednego pliku nie może przekroczyć 10 MB.</p>
                                        </>
                                    </label>
                                </div>
                                {/* eslint-disable-next-line */}
                                <div className={ classnames('form__input-file--mobile', { 'form__input-file--hide': maxFiles <= 0 }) } { ...getRootProps() } onClick={ (e) => e.stopPropagation() } >
                                    <input id="drop-files-mobile" { ...getInputProps() } />
                                    <label htmlFor="drop-files-mobile">
                                        Dodaj plik
                                    </label>
                                </div>
                            </>
                        )}
                    </Dropzone>
                </div>
                <div className="form__file-limit">
                    <div>Możesz dodać max. 10 plików.</div>
                    <div>Waga jednego pliku nie powinna przekroczyć 10 MB.</div>
                </div>
                <ul className="form__file-list">
                    {
                        files.map((file, index) => (
                            <li className="form__file" key={ index }>
                                <Img src={ attachment } alt="załącznik" />
                                <span>{ file.name }</span>
                                <div>
                                    <button className="form__file-remove" type="button" onClick={ () => removeFile(file.name) }> </button>
                                </div>
                            </li>
                        ))
                    }
                </ul>
                <p className="form__policy">
                    Administratorem Twoich danych jest Producent Drzwi &quot;BARAŃSKI&quot; Spółka Jawna Ignacy Barański
                    i Zbigniew Barański z siedzibą przy Babięty Wielkie 54, 14-240 Susz, Polska.
                    Klikając &quot;Wyślij&quot; potwierdzasz zapoznanie się z niniejszą&nbsp;
                    <a href="https://www.google.com/" target="_blank" rel="noreferrer">Polityką prywatności.</a>
                </p>
                <div className="form__button-wrapper">
                    <button
                        className="form__button"
                        type="submit"
                    >
                        Wyślij
                    </button>
                    <p className={ classnames('form__send-status', { 'form__send-status--error': isSendError }) }>
                        {
                            isSendSuccess && !isSendError
                            && 'Twoja wiadomość została wysłana. Odezwiemy się wkrótce. :)'
                        }
                        {
                            !isSendSuccess && isSendError
                            && 'Błąd wysyłania wiadomości, spróbuj jeszcze raz.'
                        }
                    </p>
                </div>
            </form>
        </div>
    </section>
);

ContactFormView.propTypes = {
    fields: PropTypes.oneOfType([PropTypes.object]).isRequired,
    files: PropTypes.instanceOf(Array).isRequired,
    doorTypes: PropTypes.instanceOf(Array).isRequired,
    maxFiles: PropTypes.number.isRequired,
    setFiles: PropTypes.func.isRequired,
    setMaxFiles: PropTypes.func.isRequired,
    removeFile: PropTypes.func.isRequired,
    validateSingleField: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onChangeSelect: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    isSendSuccess: PropTypes.bool.isRequired,
    isSendError: PropTypes.bool.isRequired
};