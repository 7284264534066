import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Errors } from 'utilities/components/errors/view';

import './view.scss';

export const Select = ({
    options,
    errors,
    placeholder,
    value,
    onChange
}) => {
    const [isVisible, setIsVisible] = useState(false);

    return (
        <div className={ classnames('input-select', { 'input-select--open': isVisible }, { 'input-select--error': Boolean(errors.size) }, { 'input-select--empty': !value }) }>
            <button
                className="input-select__title"
                type="button"
                onClick={ () => {
                    setIsVisible((prevState) => !prevState);
                } }
            >
                { value || placeholder }
            </button>
            <ul className="input-select__list">
                {
                    options.map((currentValue, index) => (
                        <li className="input-select__item" key={ index }>
                            <button
                                className="input-select__button"
                                type="button"
                                onClick={ () => {
                                    onChange(options[index]);
                                    setIsVisible(false);
                                } }
                            >
                                { currentValue }
                            </button>
                        </li>
                    ))
                }
            </ul>
            <Errors errors={ errors } />
        </div>
    );
};

Select.propTypes = {
    options: PropTypes.instanceOf(Array).isRequired,
    errors: PropTypes.instanceOf(Set).isRequired,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};