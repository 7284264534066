import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const InstagramFeed = ({ refId, paddingTop }) => {
    const initiateScript = () => {
        // eslint-disable-next-line func-names
        (function (d, s, id) {
            let js;
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://embedsocial.com/cdn/ht.js';
            d.getElementsByTagName('head')[0].appendChild(js);
        }(document, 'script', 'EmbedSocialHashtagScript'));
    };

    useEffect(() => {
        initiateScript();

        return () => {
            const scriptElem = document.getElementById('EmbedSocialHashtagScript');
            scriptElem.remove();
        };
    }, []);

    return (
        <div style={{ paddingTop }}>
            <div className="embedsocial-hashtag" data-ref={ refId } />
        </div>
    );
};

InstagramFeed.propTypes = {
    refId: PropTypes.string.isRequired,
    paddingTop: PropTypes.string
};

InstagramFeed.defaultProps = {
    paddingTop: '30px'
};

export default InstagramFeed;
