import React, { useState, useEffect } from 'react';
import { ContactFormView } from './view';
import _ from 'lodash';
import axios from 'axios';
import { API_URL } from 'utilities/consts';

export const ContactForm = () => {
    const initialFormData = {
        doorType: {
            name: 'doorType',
            value: '',
            required: true,
            errors: new Set()
        },
        firstName: {
            name: 'firstName',
            value: '',
            required: true,
            maxLength: 191,
            errors: new Set()
        },
        lastName: {
            name: 'lastName',
            value: '',
            required: true,
            maxLength: 191,
            errors: new Set()
        },
        email: {
            name: 'email',
            value: '',
            required: true,
            maxLength: 191,
            errors: new Set()
        },
        phone: {
            name: 'phone',
            value: '',
            required: false,
            maxLength: 20,
            errors: new Set()
        },
        message: {
            name: 'message',
            value: '',
            required: true,
            maxLength: 10000,
            errors: new Set()
        }
    };

    const [fields, setFields] = useState(initialFormData);

    const errorMessages = {
        empty: 'Pole wymagane.',
        email: 'Nieprawidłowy format pola „E-mail”.',
        phone: 'Nieprawidłowy format pola „Telefon”.'
    };

    const doorTypes = ['Drzwi wewnętrzne', 'Drzwi zewnętrzne', 'Drzwi klatkowe i techniczne'];

    const [isSendSuccess, setIsSendSuccess] = useState(false);
    const [isSendError, setIsSendError] = useState(false);

    const [files, setFiles] = useState([]);

    const [maxFiles, setMaxFiles] = useState(10);

    const [isInvalid, setIsInvalid] = useState(true);

    useEffect(() => setMaxFiles(10 - files.length), [files]);

    const removeFile = (name) => setFiles((prevState) => prevState.filter((file) => file.name !== name));

    const checkEmail = (email) => {
        // eslint-disable-next-line no-useless-escape
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const checkPhone = (number) => {
        // eslint-disable-next-line no-useless-escape
        const re = /(^|\W)(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)/;
        return re.test(String(number).toLowerCase());
    };

    const validate = () => {
        const newFields = _.cloneDeep(fields);
        Object.keys(newFields).forEach((field) => {
            if (newFields[field].required) {
                if (!newFields[field].value) {
                    newFields[field].errors.add(errorMessages.empty);
                } else {
                    newFields[field].errors.delete(errorMessages.empty);
                }
            }
        });

        if (checkEmail(newFields.email.value)) {
            newFields.email.errors.delete(errorMessages.email);
        } else {
            newFields.email.errors.add(errorMessages.email);
        }

        if (!newFields.phone.value || checkPhone(newFields.phone.value)) {
            newFields.phone.errors.delete(errorMessages.phone);
        } else {
            newFields.phone.errors.add(errorMessages.phone);
        }

        setFields(newFields);
    };

    const validateSingleField = (field) => {
        const newFields = _.cloneDeep(fields);
        if (newFields[field].required) {
            if (!newFields[field].value) {
                newFields[field].errors.add(errorMessages.empty);
            } else {
                newFields[field].errors.delete(errorMessages.empty);
            }
        }

        if (field === fields.email.name) {
            if (checkEmail(newFields.email.value)) {
                newFields.email.errors.delete(errorMessages.email);
            } else {
                newFields.email.errors.add(errorMessages.email);
            }
        }

        if (field === fields.phone.name) {
            if (!newFields.phone.value || checkPhone(newFields.phone.value)) {
                newFields.phone.errors.delete(errorMessages.phone);
            } else {
                newFields.phone.errors.add(errorMessages.phone);
            }
        }

        setFields(newFields);
    };

    useEffect(() => {
        if (fields?.doorType?.value) {
            validateSingleField(fields.doorType.name);
        }
    }, [fields.doorType.value]);

    const onChange = (e) => {
        const { target } = e;
        const { value, name } = target;

        setFields((prevState) => (
            {
                ...prevState,
                [name]: {
                    ...prevState[name],
                    value
                }
            }
        ));
    };

    const onChangeSelect = (e) => {
        setFields((prevState) => ({
            ...prevState,
            doorType: {
                ...prevState.doorType,
                value: e
            }
        }));
    };

    const sendCustomProject = async () => {
        try {
            const formData = new FormData();
            formData.append('name', fields.firstName.value);
            formData.append('surname', fields.lastName.value);
            formData.append('content', fields.message.value);
            formData.append('phone', fields.phone.value);
            formData.append('email', fields.email.value);
            formData.append('door_type', fields.doorType.value);
            files.forEach((item, index) => formData.append(`file${index}`, item));

            const res = await axios.post(
                `${API_URL}/customerproject`,
                formData
            );

            setIsSendSuccess(true);
            setIsSendError(false);
            setFields(initialFormData);
            setFiles([]);

            return res.data;
        } catch (e) {
            setIsSendSuccess(false);
            setIsSendError(true);

            return e;
        }
    };

    useEffect(() => {
        const isError = _.find(fields, (field) => field.errors.size > 0);
        setIsInvalid(isError || !fields.firstName.value);
    }, [fields]);

    const onSubmit = async (e) => {
        e.preventDefault();
        validate();
        if (!isInvalid) {
            await sendCustomProject();
        }
    };

    return (
        <ContactFormView
            fields={ fields }
            files={ files }
            doorTypes={ doorTypes }
            setFiles={ setFiles }
            maxFiles={ maxFiles }
            setMaxFiles={ setMaxFiles }
            removeFile={ removeFile }
            validateSingleField={ validateSingleField }
            onChange={ onChange }
            onSubmit={ onSubmit }
            onChangeSelect={ onChangeSelect }
            isSendSuccess={ isSendSuccess }
            isSendError={ isSendError }
        />
    );
};