import React from 'react';
import PropTypes from 'prop-types';

import './view.scss';

export const Errors = ({ errors }) => (
    <ul className="errors">
        {
            Boolean(errors.size)
            && <li>{ Array.from(errors)[0] }</li>
        }
    </ul>
);

Errors.propTypes = {
    errors: PropTypes.instanceOf(Set).isRequired
};